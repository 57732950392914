@import url("https://fonts.googleapis.com/css2?family=Quicksand:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Quicksand", sans-serif !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.cursor,
.logo {
  cursor: pointer;
}

[dir="rtl"] .flipRTL{
  transform: scaleX(-1);
}

@media screen and (max-width: 769px) {
  .navbar-collapse {
    background: #004e52 !important;
    background-color: #004e52 !important;
  }
}

/* width */
::-webkit-scrollbar {
  width: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d56c33 !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #004e52 !important;
}

.theme-footer-three .top-footer .footer-list ul li a:hover {
  color: #d56c33 !important;
}

@media screen and (max-width: 768px) {
  .text-center1 {
    text-align: center !important;
  }
}

@media screen and (max-width: 426px) {
  .hero-banner-five {
    background-image: url("/images/homemobilebg.png") !important;
    background-position-y: 50px !important;
  }
}

@media screen and (max-width: 991px) {
  .mobcenter {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0px;
  }

  .mobcenter .avtarimage {
    width: 70px !important;
    height: 70px !important;
  }

  .mobcenter3 {
    flex-direction: column;
  }

  .mobcenter2 {
    width: 100% !important;
  }

  .mobcenter2,
  .mobcenter2 p,
  .mobcenter2 h3 {
    text-align: center !important;
  }
}

.startformating {
  width: 100%;
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 15px !important;
}

.startformating span {
  width: 45px !important;
  height: 45px !important;
  overflow: unset !important;
  padding: 15px !important;
  font-size: 70px !important;
  float: unset !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 5px !important;
  color: #ececec !important;
}

.startformating span::before {
  content: unset !important;
}

.profile__img {
  border-radius: 100px;
  -o-object-fit: cover;
     object-fit: cover;
}

.modalmodified .modal-content {
  border: none;
  border-radius: 25px;
}

.modalmodified .modal-content .modal-body {
  padding: 0;
  margin: 0;
  position: relative;
}

.modalmodified .modal-content .modal-body .modal_closeIcon {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 2%;
  top: 3%;
  color: #fff;
  cursor: pointer;
}

.arabicPrivacyDiv h1,
h2 {
  color: rgb(1, 78, 82);
}

@media screen and (max-width: 991px) {
  .mobilcolum {
    flex-direction: column !important;
  }
}

.privacy.rtl * {
  text-align: right !important;
}

@media screen and (max-width: 576px) {
  .mobilearabic * {
    direction: rtl !important;
    text-align: right !important;
  }
  /* .privacy{
    margin: 15px !important;
  } */
}

